<template>
  <div>
    <table class="table table-striped" v-if="articles.length > 0">
      <tbody>
      <tr v-for="(article, index) in articles" :key="`item-${index}`">
        <td>
          {{ article.field.title }}
          <small><strong>{{ article.orderDate | prettyDateTime }}</strong></small>
          <span style="float:right">
                            <app-article-status
                              :article="article"
                            >
                            </app-article-status>
                             <router-link
                               tag="a"
                               class="btn btn-default btn-sm"
                               target="_blank"
                               :title="article.documentId"
                               :to="{ name: 'article_detail', params: { id: article.id }}"
                             >
                                <i class="fa fa-info"></i>
                            </router-link>
                        </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CoreApi from '../../../api/core'
import ArticleStatus from '../../article/ArticleStatus'

export default {
  name: 'VideoArticlesOld',
  props: {
    video: {
      type: Object
    }
  },
  data () {
    return {
      articles: []
    }
  },
  components: {
    appArticleStatus: ArticleStatus
  },
  methods: {
    getArticlesByIds (articleIds) {
      CoreApi().get('article?view=minimal&filter_in[documentId]=' + articleIds)
        .then(response => {
          this.articles = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    const videoArticleIds = []
    this.video.articles.forEach(article => {
      videoArticleIds.push(article.articleDocId)
    })
    this.getArticlesByIds(videoArticleIds)
  }
}
</script>

<style lang="scss">
.btn-ready {
  background: #ffb22b;
}
</style>
