<template>
  <app-modal
    :title="$t('hp.delete_modal_header')"
    modal-size="modal-md"
    @close="close"
    close-on-click-self
    data-test="hp_close_modal"
  >
    <!-- Body -->
    <template slot="body">
      {{ $t('modal.delete_modal_text') }}
    </template>

    <!-- Footer -->
    <template slot="footer">
      <button
        type="button"
        class="btn btn-inverse router-link-active"
        @click="close"
        data-test="hp_item_delete_cancel"
      >
        {{ $t('modal.delete_modal_cancel_btn') }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        @click="deleteRecord"
        data-test="hp_item_delete_confirm"
      >
        {{ $t('modal.delete_modal_confirm_btn') }}
      </button>
    </template>
  </app-modal>
</template>

<script>
import Modal from '@/components/shared/Modal'

export default {
  name: 'VideoAnnotationDeleteModal',
  props: {
    annotation: {
      type: Object
    }
  },
  components: {
    appModal: Modal
  },
  methods: {
    deleteRecord () {
      this.$emit('delete-record', this.annotation)
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
