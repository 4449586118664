<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <h3 class="form-edit-header">
          {{ $t('video.statistics') }}
          <app-button-csv-export :data="getStatsExportData()" filename="video-stats"></app-button-csv-export>
        </h3>
      </div>
      <div class="col-lg-3 col-md-6">
        <app-datepicker
          type="date"
          v-model="filter.startDate"
          id="filter_startDate"
          :label="$t('videoStats.filter.date_from')"
          disable-form-group
        >
        </app-datepicker>
      </div>
      <div class="col-lg-3 col-md-6">
        <app-datepicker
          type="date"
          v-model="filter.endDate"
          id="filter_endDate"
          :label="$t('videoStats.filter.date_to')"
          disable-form-group
        >
        </app-datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <table class="table table-striped" v-if="videoStatsData">
          <thead>
          <tr>
            <th>{{ $t('videoStats.videoplays') }}</th>
            <th>{{ $t('videoStats.video_starts') }}</th>
            <th>{{ $t('videoStats.replays') }}</th>
            <th>{{ $t('videoStats.videoviews') }}</th>
            <th>{{ $t('videoStats.ad_starts') }}</th>
            <th>{{ $t('videoStats.unique_users_count') }}</th>
            <th>{{ $t('videoStats.avgPassPerc') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              {{ videoStatsData.videoPlays.total | formatNumber }}
            </td>
            <td>
              {{ videoStatsData.videoStarts.total | formatNumber }}
            </td>
            <td>
              {{ videoStatsData.replays.total | formatNumber }}
            </td>
            <td>
              {{ videoStatsData.videoViews.total | formatNumber }}
            </td>
            <td>
              {{ videoStatsData.adStarts.total | formatNumber }}
            </td>
            <td>
              {{ videoStatsData.uniqueUsersCount.total | formatNumber }}
            </td>
            <td>
              {{ passAvgPlaysTotal }} %
            </td>
          </tr>
          </tbody>
        </table>
        <div style="height: 330px;">
          <app-preloader v-if="loading"></app-preloader>
          <app-line-chart
            :chart-data="videoStatsChartData"
            :height="330"
            :max-labels="24"
            :display-legend="false"
          >
          </app-line-chart>
        </div>
      </div>
    </div>
    <div class="row m-t-30">
      <div class="col-lg-12">
        <h3 class="form-edit-header">{{ $t('video.playerPerformance') }}</h3>
        <p
          v-if="videoAssetPlayerPerfData.length === 0"
          class="alert alert-info"
        >
          {{ $t('videoStats.no_data_found') }}
        </p>
        <table
          v-if="videoAssetPlayerPerfData.length > 0"
          class="table table-striped"
        >
          <thead>
          <tr>
            <th>{{ $t('video.player') }}</th>
            <th>{{ $t('videoStats.videoplays') }}</th>
            <th>{{ $t('videoStats.video_starts') }}</th>
            <th>{{ $t('videoStats.replays') }}</th>
            <th>{{ $t('videoStats.videoviews') }}</th>
            <th>{{ $t('videoStats.pass25Plays') }}</th>
            <th>{{ $t('videoStats.pass50Plays') }}</th>
            <th>{{ $t('videoStats.pass75Plays') }}</th>
            <th>{{ $t('videoStats.pass100Plays') }}</th>
            <th>{{ $t('videoStats.avgPassPerc') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(record, index) in videoAssetPlayerPerfData" :key="`item-${index}`">
            <td>
              {{ record.brand }} {{ record.videoPlayerPosition }}
            </td>
            <td>
              {{ record.videoPlays }}
            </td>
            <td>
              {{ record.videoStarts }}
            </td>
            <td>
              {{ record.replays }}
            </td>
            <td>
              {{ record.videoViews }}
            </td>
            <td>
              {{ record.pass25Plays }}
            </td>
            <td>
              {{ record.pass50Plays }}
            </td>
            <td>
              {{ record.pass75Plays }}
            </td>
            <td>
              {{ record.pass100Plays }}
            </td>
            <td>
              {{ record.avgPassPerc }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Preloader from '@/components/preloader/Preloader'
import { dataHubVideoApi } from '@/api/datahub'
import Datepicker from '@/components/form/Datepicker'
import LineChart from '@/components/chart/LineChart'
import { chartColors } from '@/services/videoStats/VideoChartColors'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'

export default {
  name: 'VideoStatsOld',
  props: {
    video: {
      type: Object
    }
  },
  components: {
    appPreloader: Preloader,
    appDatepicker: Datepicker,
    appLineChart: LineChart,
    appButtonCsvExport: ButtonCsvExport
  },
  data () {
    return {
      loading: false,
      chartColors: chartColors,
      videoStatsData: null,
      videoAssetPlayerPerfData: [],
      filter: {
        startDate: this.getDefaultStartDate(),
        endDate: this.getDefaultEndDate()
      }
    }
  },
  computed: {
    selectedStartDate () {
      return this.filter.startDate
    },
    selectedEndDate () {
      return this.filter.endDate
    },
    passAvgPlaysTotal () {
      const pass25plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass25Plays, 0)
      const pass50plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass50Plays, 0)
      const pass75plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass75Plays, 0)
      const pass100plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass100Plays, 0)

      return Math.round(
        (
          pass100plays * 100 +
          pass75plays * 75 +
          pass50plays * 50 +
          pass25plays * 25
        ) /
        (
          pass100plays +
          pass75plays +
          pass50plays +
          pass25plays
        )
      ) || 0
    },
    videoStatsChartData () {
      if (this.videoStatsData === null) {
        return {
          labels: [],
          datasets: []
        }
      }

      const datasets = [
        {
          label: 'Video plays ',
          borderColor: chartColors.videoPlays,
          backgroundColor: chartColors.videoPlays,
          fill: false,
          data: this.videoStatsData.videoPlays.metrics
        },
        {
          label: 'Video starts',
          borderColor: chartColors.videoStarts,
          backgroundColor: chartColors.videoStarts,
          fill: false,
          data: this.videoStatsData.videoStarts.metrics
        },
        {
          label: 'Replays',
          borderColor: chartColors.replays,
          backgroundColor: chartColors.replays,
          fill: false,
          data: this.videoStatsData.replays.metrics
        },
        {
          label: 'Video views',
          borderColor: chartColors.videoViews,
          backgroundColor: chartColors.videoViews,
          fill: false,
          data: this.videoStatsData.videoPlays.metrics.map((videoPlay, index) => videoPlay + this.videoStatsData.replays.metrics[index])
        },
        {
          label: 'AD starts',
          borderColor: chartColors.adStarts,
          backgroundColor: chartColors.adStarts,
          fill: false,
          data: this.videoStatsData.adStarts.metrics
        },
        {
          label: 'Unique users',
          borderColor: chartColors.playConversionRate,
          backgroundColor: chartColors.playConversionRate,
          fill: false,
          data: this.videoStatsData.uniqueUsersCount.metrics
        }
      ]

      return {
        labels: this.formatTimeGroupDates(this.videoStatsData.dimensions),
        datasets: datasets
      }
    }
  },
  watch: {
    selectedStartDate () {
      this.getVideoStats()
      this.getVideoAssetPlayerPerf()
    },
    selectedEndDate () {
      this.getVideoStats()
      this.getVideoAssetPlayerPerf()
    }
  },
  methods: {
    getDefaultStartDate () {
      const now = moment(this.video.createdAt)
      now.set({ hour: 0, minutes: 0, second: 0, millisecond: 0 })
      return now.toISOString()
    },
    getDefaultEndDate () {
      const end = moment()
      end.set({ hour: 0, minutes: 0, second: 0, millisecond: 0 })
      return end.toISOString()
    },
    formatTimeGroupDates (timeGroups, format = 'DD.MM HH:mm', addDay = 0) {
      return timeGroups.map(timeGroup => {
        if (addDay > 0) {
          return moment(timeGroup).add(addDay, 'days').format(format)
        }
        return moment(timeGroup).format(format)
      })
    },
    getVideoApiUrl (url) {
      const formatDate = (date) => moment(date).format('YYYY-MM-DD')
      const fromDate = formatDate(this.filter.startDate)
      const endDate = formatDate(this.filter.endDate)
      return `${url}?videoId=${this.video.id}&fromDate=${fromDate}&toDate=${endDate}`
    },
    getVideoStats () {
      const url = this.getVideoApiUrl('/videoDetails')
      this.loading = true
      dataHubVideoApi().get(url)
        .then(response => {
          this.videoStatsData = response.data
          this.videoStatsData.videoViews = {
            total: this.videoStatsData.videoPlays.total + this.videoStatsData.replays.total
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getVideoAssetPlayerPerf () {
      const url = this.getVideoApiUrl('/videoPlayerPerf')
      dataHubVideoApi().get(url)
        .then(response => {
          this.videoAssetPlayerPerfData = response.data
          this.videoAssetPlayerPerfData.forEach(playerPerf => {
            playerPerf.videoViews = playerPerf.videoPlays + playerPerf.replays
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    getStatsExportData () {
      if (!this.videoStatsData) {
        return []
      }
      const rows = this.videoStatsData.dimensions.map(
        (dateTime, idx) => [
          dateTime,
          this.videoStatsData.videoPlays.metrics[idx],
          this.videoStatsData.videoStarts.metrics[idx],
          this.videoStatsData.replays.metrics[idx],
          this.videoStatsData.adStarts.metrics[idx],
          this.videoStatsData.uniqueUsersCount.metrics[idx]
        ]
      )
      rows.unshift([
        '',
        this.videoStatsData.videoPlays.total,
        this.videoStatsData.videoStarts.total,
        this.videoStatsData.replays.total,
        this.videoStatsData.adStarts.total,
        this.videoStatsData.uniqueUsersCount.total,
        this.passAvgPlaysTotal
      ])
      rows.unshift([
        '',
        this.$i18n.t('videoStats.videoplays'),
        this.$i18n.t('videoStats.video_starts'),
        this.$i18n.t('videoStats.replays'),
        this.$i18n.t('videoStats.ad_starts'),
        this.$i18n.t('videoStats.unique_users_count'),
        this.$i18n.t('videoStats.avgPassPerc')
      ])
      return rows
    }
  },
  created () {
    this.getVideoStats()
    this.getVideoAssetPlayerPerf()
  }
}
</script>
