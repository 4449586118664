export const chartColors = {
  displays: 'rgb(255, 99, 132)',
  inViewDisplays: 'rgb(255, 159, 64)',
  videoPlays: 'rgb(255, 205, 86)',
  videoViews: 'rgb(153, 102, 255)',
  adStarts: 'rgb(75, 192, 192)',
  adSkipRate: 'rgb(153, 102, 255)',
  adFinishRate: 'rgb(255, 99, 132)',
  adConversionRate: 'rgb(0,128,128)',
  playConversionRate: 'rgb(0,128,128)',
  videoStarts: 'rgb(54, 162, 235)',
  replays: 'rgb(212,55,144)',
  totalVideoViews: 'rgb(225,48,81)',
  pass25Plays: 'rgb(153, 102, 255)',
  pass50Plays: 'rgb(75, 192, 192)',
  pass75Plays: 'rgb(255, 159, 64)',
  pass100Plays: 'rgb(255, 99, 132)',
  avgPassPerc: 'rgb(0, 128, 128)',
  uniqueUsers: 'rgb(252, 186, 3)',
  interactions: 'rgb(75, 192, 192)'
}
