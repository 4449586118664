<template>
  <app-module-view>
    <template slot="body">

      <!-- TOP BAR -------------------------------------------->
      <section class="m-b-10">
        <div class="row">
          <div class="col-sm-4">
            <app-button-edit
              route-name="video_edit_old"
              :record-id="video.id"
            >
            </app-button-edit>
            <app-button-copy-to-clipboard
              label="ID"
              :data="video.id | toString"
              data-test="video_btn_copy_to_clipboard"
              :notify-message="$t('notify.id_was_copied')"
            >
            </app-button-copy-to-clipboard>
            <a
              v-if="downloadData.src"
              @click="downloadVideo"
              class="btn btn-inverse text-white"
            >
              <i class="fas fa-download"></i>
              <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              {{ $t('video.download') }}
            </a>
          </div>
          <div class="col-lg-4 text-right">
            <span>{{ $t('video.status') }}: </span>
            <app-video-status
              :video="video"
            >
            </app-video-status>
            <br v-if="video.statusMessage">
            <small class="text-danger" v-if="video.statusMessage">{{ video.statusMessage }}</small><br>
            <router-link
              v-if="video.mainArticleDocId"
              tag="a"
              :to="{name: 'articleByDocumentId', params: {id: video.mainArticleDocId}}"
              target="_blank"
              class="label label-warning article-info"
            >
              <i class="fas fa-info-circle"></i> {{ $t('video.video_article_exists') }}
            </router-link>
            <small>
              {{ $t('video.created_by') }}:
              <strong>{{ userNameById(video.createdBy) }} {{ video.createdAt | prettyDateTime }}</strong>
            </small>
          </div>
          <div class="col-sm-4">
            <div class="text-right m-r-5">
              <app-button-delete @deleteRecord="deleteVideo"></app-button-delete>
              <app-button-close route-name="video_list_old"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <h3 class="form-edit-header">{{ $t('video.main_content') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <th width="200">Id</th>
                  <td>{{ video.id }}</td>
                </tr>
                <tr>
                  <th>{{ $t('video.type') }}</th>
                  <td>
                    <app-video-type-icon :video="video"></app-video-type-icon>
                    <span v-if="video.type === 'ooyala'">{{ $t('video.video_type_value.ooyala') }}</span>
                    <span v-if="video.type === 'youtube'">{{ $t('video.video_type_value.youtube') }}</span>
                    <span v-if="video.type === 'livebox'">{{ $t('video.video_type_value.livebox') }}</span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.meta_title') }}</th>
                  <td>{{ video.meta.title }}</td>
                </tr>
                <tr>
                  <th>{{ $t('video.meta_keywords') }}</th>
                  <td>{{ video.meta.keywords }}</td>
                </tr>
                <tr>
                  <th>{{ $t('video.meta_description') }}</th>
                  <td>{{ video.meta.description }}</td>
                </tr>
                <tr>
                  <th>{{ $t('video.author') }}</th>
                  <td>
                                     <span
                                       v-for="(user, index) in video.expanded.authorUsers"
                                       :key="`item-${index}`"
                                       class="label label-info m-r-5"
                                     >
                                          {{ user.username }}
                                        </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.camera') }}</th>
                  <td>
                                     <span
                                       v-for="(user, index) in video.expanded.cameraUsers"
                                       :key="`item-${index}`"
                                       class="label label-info m-r-5"
                                     >
                                          {{ user.username }}
                                        </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.editing') }}</th>
                  <td>
                                     <span
                                       v-for="(user, index) in video.expanded.editingUsers"
                                       :key="`item-${index}`"
                                       class="label label-info m-r-5"
                                     >
                                          {{ user.username }}
                                        </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <app-eagle-video
                    v-if="['live','published'].some(v => v === video.status)"
                    :video="video"
                  >
                  </app-eagle-video>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <app-video-annotation-list
                :video="video"
                @change="getVideo"
              >
              </app-video-annotation-list>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <app-video-stats
                :video="video"
              >
              </app-video-stats>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h3 class="form-edit-header">{{ $t('video.main_settings') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <th>{{ $t('video.default_site') }}</th>
                  <td>{{ video.expanded.defaultSite.title }}</td>
                </tr>
                <tr>
                  <th>{{ $t('video.categories') }}</th>
                  <td>
                                     <span
                                       v-for="(category, index) in video.expanded.categories"
                                       :key="`item-${index}`"
                                       class="label label-info m-r-5"
                                     >
                                          {{ category.title }}
                                        </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.video_show') }}</th>
                  <td>
                                     <span
                                       v-for="(videoShow, index) in video.expanded.videoShows"
                                       :key="`item-${index}`"
                                       class="label label-info m-r-5"
                                     >
                                          {{ videoShow.title }}
                                        </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.safety_topics') }}</th>
                  <td>
                                     <span
                                       v-for="(safetyTopic, index) in video.expanded.safetyTopics"
                                       :key="`item-${index}`"
                                       class="label label-info m-r-5"
                                     >
                                          {{ safetyTopic.title }}
                                        </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_editorial_content') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.editorialContent"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_agency_content') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.agencyContent"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_marketing_content') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.marketingContent"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_native_content') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.nativeContent"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_internal') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.internal"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_social') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.social"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_agency') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.agency"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_for_adults') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.forAdults"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_product_placement') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.productPlacement"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_exclusive') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.exclusive"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_no_ads') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.noAds"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_camera') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.camera"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_mobile_phone') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.mobilePhone"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('video.setting_slideshow') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="video.setting.slideshow"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <app-detail-system-rows :record="video"></app-detail-system-rows>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h3 class="form-edit-header">{{ $t('video.headline_video_parameters') }}</h3>
                  <table class="table table-striped">
                    <tbody>
                    <tr v-if="video.liveboxAssetId">
                      <th>{{ $t('video.livebox_asset_id') }}</th>
                      <td>{{ video.liveboxAssetId }}</td>
                    </tr>
                    <tr v-if="video.youtubeId">
                      <th>{{ $t('video.youtube_id') }}</th>
                      <td>{{ video.youtubeId }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('video.video_duration') }}</th>
                      <td>{{ video.videoDuration | formatMilliseconds }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('video.video_width') }}</th>
                      <td>{{ video.videoWidth }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('video.video_height') }}</th>
                      <td>{{ video.videoHeight }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('video.file_size') }}</th>
                      <td>{{ getVideoSize(video.fileSize) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Video articles --------------------->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h3 class="form-edit-header">{{ $t('video.headline_used_in_articles') }}</h3>
                  <p
                    class="alert alert-info"
                    v-if="video.articles.length === 0"
                  >
                    {{ $t('video.video_article_not_found') }}
                  </p>
                  <app-video-articles
                    v-if="video.articles.length > 0"
                    :video="video"
                  >
                  </app-video-articles>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-modal
        v-if="cancelRequest"
        :title="$t('modal.download_progress')"
        @close="cancelDownload"
      >
        <template slot="body">
          <progress max="100" :value="downloadProgress"></progress>
        </template>
      </app-modal>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import DetailSystemRows from '@/components/shared/DetailSystemRows'
import VideoModel from '@/model/Video'
import ButtonEdit from '@/components/shared/ButtonEdit'
import ButtonDelete from '@/components/shared/ButtonDelete'
import ButtonClose from '@/components/shared/ButtonClose'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import NotifyService from '@/services/NotifyService'
import EagleVideo from '@/components/shared/EagleVideo'
import VideoStatus from '@/components/video/VideoStatus'
import VideoTypeIcon from '@/components/video/VideoTypeIcon'
import VideoAnnotationList from '@/components/video/VideoAnnotationList'
import VideoArticles from '@/components/video/old/VideoArticlesOld'
import UserService from '@/services/user/UserService'
import VideoStats from '@/components/video/old/VideoStatsOld'
import CoreApi from '@/api/core'
import Axios from 'axios'
import Modal from '@/components/shared/Modal'

export default {
  name: 'VideoViewOld',
  data () {
    return {
      downloadData: {},
      video: VideoModel,
      downloadProgress: 0,
      cancelRequest: null,
      error: null
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonEdit: ButtonEdit,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appButtonCopyToClipboard: BtnCopyToClipboard,
    appDetailSystemRows: DetailSystemRows,
    appVideoStatus: VideoStatus,
    appVideoTypeIcon: VideoTypeIcon,
    appEagleVideo: EagleVideo,
    appVideoAnnotationList: VideoAnnotationList,
    appVideoArticles: VideoArticles,
    appModal: Modal,
    appVideoStats: VideoStats
  },
  methods: {
    getVideo () {
      this.$store.dispatch('video/fetchOne', this.$route.params.id)
        .then(async () => {
          this.video = this.$store.getters['video/detail']
          if (this.video.liveboxAssetId) {
            this.downloadData = (await CoreApi().get(`/livebox/${this.video.liveboxAssetId}/request_video_download`)).data
          }
        })
    },
    async downloadVideo () {
      this.cancelRequest = Axios.CancelToken.source()
      return await Axios.get(this.downloadData.src, {
        responseType: 'blob',
        headers: {
          Authorization: 'Basic ' + btoa(`${this.downloadData.username}:${this.downloadData.password}`)
        },
        onDownloadProgress: ({ loaded, total }) => {
          this.downloadProgress = Math.floor(loaded / total * 100)
        },
        cancelToken: this.cancelRequest.token
      })
        .then(response => {
          this.downloadProgress = 0
          this.cancelRequest = null
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.video.fileName)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteVideo () {
      this.$store.dispatch('video/deleteRecord', this.video)
        .then(() => {
          if (this.$store.getters['video/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/videoOld')
          } else {
            NotifyService.setErrorMessage(this.$t('video.' + this.$store.getters['video/error']))
          }
        })
        .catch(error => console.log(error))
    },
    getVideoSize (fileSize) {
      if (fileSize) {
        return Math.round(fileSize * 1000 / 1048576) / 1000 + ' MB'
      } else {
        return ''
      }
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    cancelDownload () {
      this.cancelRequest.cancel()
      this.cancelRequest = null
      this.downloadProgress = 0
    }
  },
  mounted () {
    this.getVideo()
  }
}
</script>

<style lang="scss" scoped>
progress {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}
</style>
