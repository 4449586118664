<template>
  <div>
    <small>
      <strong>{{ $t('video.numberOfViews') }}: </strong>{{ annotationData.displays }}
    </small>
    <small>
      <strong>{{ $t('video.numberOfClicks') }}: </strong>{{ annotationData.clicks }}
    </small>
    <small>
      <strong>{{ $t('video.ctr') }}: </strong>{{ annotationData.conversionRate }}
    </small>
  </div>
</template>

<script>
import EdonApi from '../../api/edon'

export default {
  name: 'AnnotationStats',
  props: {
    annotation: {
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      annotationData: null
    }
  },
  methods: {
    getAnnotationStats () {
      this.loading = true
      EdonApi().get('/videoStats/annotation/' + this.annotation.id)
        .then(response => {
          this.annotationData = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getAnnotationStats()
  }
}
</script>
